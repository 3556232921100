import React from "react"
import styled from "styled-components"
import { calculateRem } from "../../utils/javascript-styles"

import { HrefButtonInverse } from "../Button"

const Card = styled.div`
  width: 100%;
  margin: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a;
`

const CardBody = styled.div`
  border-radius: 1px 1px 0 0;
  color: #fefefe;
  padding: 1em 0;
`
const Title = styled.h5`
  color: ${props => props.theme.oil};
`

const Price = styled.span`
  color: red;
  font-size: 2rem;
  margin: 0;
`

const CardFoot = styled.div`
  background: red;
  padding: 1em 0;
`
const StyledFootText = styled.p`
  color: white;
  font-weight: bold;
`

const PricingDimensionCard = ({
  id,
  title,
  price,
  description1,
  description2,
}) => {
  return (
    <Card>
      <CardBody>
        <Title>{title}</Title>

        <Price>${price}</Price>
        <div style={{ marginTop: "24px" }}>
          <HrefButtonInverse
            href="https://form.jotform.com/223006119805145"
            target="_blank"
          >
            Book Now
          </HrefButtonInverse>
        </div>
      </CardBody>
      <CardFoot>
        <StyledFootText>{description1}</StyledFootText>
        <StyledFootText>{description2}</StyledFootText>
      </CardFoot>
    </Card>
  )
}

export default PricingDimensionCard
