import React from "react"
import PlayAreas from "../components/PlayAreas"
import { Layout } from "../components/Layout"

const PlayAreasPage = () => {
  return (
    <Layout>
      <PlayAreas />
    </Layout>
  )
}

export default PlayAreasPage
