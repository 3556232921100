import React from "react"
import { Row, Col } from "react-styled-flexboxgrid"
import styled from "styled-components"

import Gallery from "react-photo-gallery"
import FlexContainer from "../FlexContainer"
import { Primary, Alternate, Secondary } from "../Section"
import PricingDimensionCard from "../PricingDimensionCard"
import Button from "../Button"
import pa1 from "../../images/play_areas/play-area-1.jpg"
import pa2 from "../../images/play_areas/play-area-2.jpg"
import pa3 from "../../images/play_areas/play-area-3.jpg"

const pricing = [
  {
    id: 0,
    title: "Snowflake",
    price: "1,830",
    description2: "Area Size: 10ft x 20ft",
  },
  {
    id: 1,
    title: "Snowball",
    price: "2,640",
    description2: "Area Size: 20ft x 20ft",
  },
  {
    id: 2,
    title: "Snowman",
    price: "3,200",
    description2: "Area Size: 30ft x 20ft",
  },
  {
    id: 3,
    title: "Snowdrift",
    price: "4,320",
    description2: "Area Size: 30ft x 30ft",
  },
  {
    id: 3,
    title: "Avalanche",
    price: "5,940",
    description2: "Area Size: 40ft x 40ft",
  },
  {
    id: 3,
    title: "Blizzard",
    price: "8,400",
    description2: "Area Size: 50ft x 40ft",
  },
]

const gallery = [
  {
    id: 0,
    src: pa1,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Boy making snow angel in snow ",
  },
  {
    id: 1,
    src: pa2,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Boy throwing a snowball",
  },
  {
    id: 2,
    src: pa3,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Play area of snow with people",
  },
]

const StyledCardContainer = styled.div`
  width: "400px";
`

const PlayAreas = () => {
  return (
    <section>
      <Alternate centered backgroundHeight={350}>
        <h1>Play Areas</h1>
      </Alternate>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <FlexContainer justifyContent="space-between">
              {pricing.map(price => {
                return (
                  <StyledCardContainer>
                    <PricingDimensionCard
                      id={price.id}
                      title={price.title}
                      price={price.price}
                      description1={price.description1}
                      description2={price.description2}
                    />
                  </StyledCardContainer>
                )
              })}
            </FlexContainer>
          </Col>
        </Row>
      </Primary>
      <Secondary centered>
        <Row>
          <Col xs={12}>
            <h3>Need more Snow? No problem.</h3>
            <h4>Call (770) 599-2862 for a quote!</h4>
            <Gallery photos={gallery} />
          </Col>
        </Row>
      </Secondary>
    </section>
  )
}

export default PlayAreas
